// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
export const flourishRight = require("../assets/432f9af9-794c-4188-8117-7d3751502be6@1x.svg");
export const flourishTopLeft = require("../assets/cd476409-efa8-4810-b7c8-0068494e85d2@1x.svg");
export const heart = require("../assets/335f3298-73c2-4b49-87f0-bdd5380a4035@1x.svg");
export const heartFill = require("../assets/fd204fbe-52a7-4ca8-becd-8a04ae285d64@1x.svg");
export const message = require("../assets/c960c1cc-f05b-4842-972b-c497154e2e5b@1x.svg");
export const repeat = require("../assets/7900b30b-3148-4379-bb23-489f4b637df5@1x.svg");
export const share = require("../assets/c0d36d31-1367-4c7a-91be-c1ac14e5c3c5@1x.svg");
export const streamlineIconServer1140X140 = require("../assets/57b8c7c8-913a-452e-bd4d-e7702b618b0b@1x.png");
export const vislyCheck = require("../assets/5cd79169-4d72-486a-bd71-20cfa0420bb2@1x.svg");
export const vislyRadioBorder = require("../assets/d0171f72-9dbe-4e54-b469-a4432d2beca6@1x.svg");
export const vislyRadioCenter = require("../assets/22ef53ca-3265-49f9-85f5-c00ff038c977@1x.svg");
export const vislySelect = require("../assets/c6f11592-eb2a-4697-9b7c-6c7c31b99f89@1x.svg");
