// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "./reset.css";
import "./MainPage.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "./_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
  IconPrimitive,
} from "./_internal_primitives";
import Input, { InputContext } from "./Input";
import ValidationResultIcon, {
  ValidationResultIconContext,
} from "./ValidationResultIcon";
import StreamlineLinkText, {
  StreamlineLinkTextContext,
} from "./StreamlineLinkText";

const styles = [
  {
    type: "default",
    layers: {
      CL61ZqDsmp: {
        none: {
          text: "Cloudflare IP Address Checker",
        },
      },
      BXyZQ1o9NC: {
        none: {
          role: "img",
          src: require("../assets/cd476409-efa8-4810-b7c8-0068494e85d2@1x.svg"),
          useMask: true,
        },
      },
      VbB9ApfSku: {
        none: {
          role: "img",
          src: require("../assets/432f9af9-794c-4188-8117-7d3751502be6@1x.svg"),
          useMask: true,
        },
      },
      TWrpGJFfX7: {
        none: {
          text: "You can try Cloudflare IPv4 and IPv6 addresses like:",
        },
      },
      "7jMX9m9fma": {
        none: {
          text: "103.21.244.0",
        },
      },
      "37p14rTgb5": {
        none: {
          text: "2400:cb00:0000::0000",
        },
      },
      "7tbHPDGCob": {
        none: {
          text: "You can also try ranges like:",
        },
      },
      "2kPXKTbjUM": {
        none: {
          text: "108.162.192.0/18",
        },
      },
      RyTM3AdUN2: {
        none: {
          text: "2405:8100::/32",
        },
      },
      PP2rFc5gvn: {
        none: {
          text: "Icon by ",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      WYqSvHwq9n: {
        none: {
          placeholder: "e.g. 103.21.244.0",
        },
      },
      PGiGejg5MR: {
        none: {
          status: "valid",
        },
      },
      "8VgbNRJb2e": {},
    },
  },
];

const variantPropTypes = [];

export const MainPageContext = createContext(null);

function MainPage(_props) {
  const defaults = useContext(MainPageContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="B3GMqQbie6"
      addSpacing={false}
      internal={{
        styles: styles,
        layerId: "B3GMqQbie6",
        scope: "9twmSf6vJZ",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            className={"__visly_reset __visly_scope_9twmSf6vJZ_GxvN5kmKeB"}
            key={"GxvN5kmKeB"}
            addSpacing={false}
          >
            {[
              <TextPrimitive
                className={"__visly_reset __visly_scope_9twmSf6vJZ_PP2rFc5gvn"}
                key={"PP2rFc5gvn"}
                text={getStyle("PP2rFc5gvn", "text")}
              />,
              <SpacerPrimitive
                className={"__visly_reset __visly_scope_9twmSf6vJZ_3yLJ28pA4W"}
                key={"3yLJ28pA4W"}
              />,
              props.StreamlineLinkText === undefined ? (
                <StreamlineLinkText
                  key={"8VgbNRJb2e"}
                  {...getCompositeDefaultProps("8VgbNRJb2e")}
                  className="__visly_reset __visly_scope_9twmSf6vJZ_8VgbNRJb2e"
                />
              ) : (
                <StreamlineLinkTextContext.Provider
                  key="8VgbNRJb2e-provider"
                  value={{
                    key: "8VgbNRJb2e",
                    className:
                      "__visly_reset __visly_scope_9twmSf6vJZ_8VgbNRJb2e",
                    ...getCompositeDefaultProps("8VgbNRJb2e"),
                  }}
                >
                  {props.StreamlineLinkText}
                </StreamlineLinkTextContext.Provider>
              ),
            ]}
          </ContainerPrimitive>
          <ContainerPrimitive
            className={"__visly_reset __visly_scope_9twmSf6vJZ_4rY6GQvaMN"}
            key={"4rY6GQvaMN"}
            addSpacing={false}
          >
            {
              <IconPrimitive
                className={"__visly_reset __visly_scope_9twmSf6vJZ_VbB9ApfSku"}
                key={"VbB9ApfSku"}
                useMask={getStyle("VbB9ApfSku", "useMask")}
                src={getStyle("VbB9ApfSku", "src")}
              />
            }
          </ContainerPrimitive>
          <ContainerPrimitive
            className={"__visly_reset __visly_scope_9twmSf6vJZ_7fBam9B1ai"}
            key={"7fBam9B1ai"}
            addSpacing={false}
          >
            {
              <IconPrimitive
                className={"__visly_reset __visly_scope_9twmSf6vJZ_BXyZQ1o9NC"}
                key={"BXyZQ1o9NC"}
                useMask={getStyle("BXyZQ1o9NC", "useMask")}
                src={getStyle("BXyZQ1o9NC", "src")}
              />
            }
          </ContainerPrimitive>
          <TextPrimitive
            className={"__visly_reset __visly_scope_9twmSf6vJZ_CL61ZqDsmp"}
            key={"CL61ZqDsmp"}
            text={getStyle("CL61ZqDsmp", "text")}
          />
          <ContainerPrimitive
            className={"__visly_reset __visly_scope_9twmSf6vJZ_MXvZeJSANT"}
            key={"MXvZeJSANT"}
            addSpacing={false}
          >
            {
              <ContainerPrimitive
                className={"__visly_reset __visly_scope_9twmSf6vJZ_DaLbFtbHmy"}
                key={"DaLbFtbHmy"}
                addSpacing={false}
              >
                {[
                  props.Input === undefined ? (
                    <Input
                      key={"WYqSvHwq9n"}
                      {...getCompositeDefaultProps("WYqSvHwq9n")}
                      className="__visly_reset __visly_scope_9twmSf6vJZ_WYqSvHwq9n"
                    />
                  ) : (
                    <InputContext.Provider
                      key="WYqSvHwq9n-provider"
                      value={{
                        key: "WYqSvHwq9n",
                        className:
                          "__visly_reset __visly_scope_9twmSf6vJZ_WYqSvHwq9n",
                        ...getCompositeDefaultProps("WYqSvHwq9n"),
                      }}
                    >
                      {props.Input}
                    </InputContext.Provider>
                  ),
                  <SpacerPrimitive
                    className={
                      "__visly_reset __visly_scope_9twmSf6vJZ_9kw1TdkdF3"
                    }
                    key={"9kw1TdkdF3"}
                  />,
                  props.ValidationResultIcon === undefined ? (
                    <ValidationResultIcon
                      key={"PGiGejg5MR"}
                      {...getCompositeDefaultProps("PGiGejg5MR")}
                      className="__visly_reset __visly_scope_9twmSf6vJZ_PGiGejg5MR"
                    />
                  ) : (
                    <ValidationResultIconContext.Provider
                      key="PGiGejg5MR-provider"
                      value={{
                        key: "PGiGejg5MR",
                        className:
                          "__visly_reset __visly_scope_9twmSf6vJZ_PGiGejg5MR",
                        ...getCompositeDefaultProps("PGiGejg5MR"),
                      }}
                    >
                      {props.ValidationResultIcon}
                    </ValidationResultIconContext.Provider>
                  ),
                ]}
              </ContainerPrimitive>
            }
          </ContainerPrimitive>
          <ContainerPrimitive
            className={"__visly_reset __visly_scope_9twmSf6vJZ_D1pafZ1DAQ"}
            key={"D1pafZ1DAQ"}
            addSpacing={false}
          >
            {
              <ContainerPrimitive
                className={"__visly_reset __visly_scope_9twmSf6vJZ_AsNNnL12JS"}
                key={"AsNNnL12JS"}
                addSpacing={false}
              >
                <TextPrimitive
                  className={
                    "__visly_reset __visly_scope_9twmSf6vJZ_TWrpGJFfX7"
                  }
                  key={"TWrpGJFfX7"}
                  text={getStyle("TWrpGJFfX7", "text")}
                />
                <ContainerPrimitive
                  className={
                    "__visly_reset __visly_scope_9twmSf6vJZ_XUxgy1mj9D"
                  }
                  key={"XUxgy1mj9D"}
                  addSpacing={false}
                >
                  <TextPrimitive
                    className={
                      "__visly_reset __visly_scope_9twmSf6vJZ_7jMX9m9fma"
                    }
                    key={"7jMX9m9fma"}
                    text={getStyle("7jMX9m9fma", "text")}
                  />
                  <TextPrimitive
                    className={
                      "__visly_reset __visly_scope_9twmSf6vJZ_37p14rTgb5"
                    }
                    key={"37p14rTgb5"}
                    text={getStyle("37p14rTgb5", "text")}
                  />
                </ContainerPrimitive>
                <SpacerPrimitive
                  className={
                    "__visly_reset __visly_scope_9twmSf6vJZ_CqDcxRPPqF"
                  }
                  key={"CqDcxRPPqF"}
                />
                <TextPrimitive
                  className={
                    "__visly_reset __visly_scope_9twmSf6vJZ_7tbHPDGCob"
                  }
                  key={"7tbHPDGCob"}
                  text={getStyle("7tbHPDGCob", "text")}
                />
                <ContainerPrimitive
                  className={
                    "__visly_reset __visly_scope_9twmSf6vJZ_33L9DsTJR6"
                  }
                  key={"33L9DsTJR6"}
                  addSpacing={false}
                >
                  <TextPrimitive
                    className={
                      "__visly_reset __visly_scope_9twmSf6vJZ_2kPXKTbjUM"
                    }
                    key={"2kPXKTbjUM"}
                    text={getStyle("2kPXKTbjUM", "text")}
                  />
                  <TextPrimitive
                    className={
                      "__visly_reset __visly_scope_9twmSf6vJZ_RyTM3AdUN2"
                    }
                    key={"RyTM3AdUN2"}
                    text={getStyle("RyTM3AdUN2", "text")}
                  />
                </ContainerPrimitive>
              </ContainerPrimitive>
            }
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

MainPage.Input = Input;
MainPage.ValidationResultIcon = ValidationResultIcon;
MainPage.StreamlineLinkText = StreamlineLinkText;
MainPage.__variants = [];

export default MainPage;
