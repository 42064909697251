// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useLayoutEffect,
} from "react";
export function exists(val) {
  return !(val === undefined || val === null);
}
export function getStyle(values, layerId, styleName) {
  if (exists(values[layerId])) {
    return values[layerId][styleName];
  }

  return undefined;
}
export function getStyleFunc(values) {
  return (layerId, styleName) => {
    return getStyle(values, layerId, styleName);
  };
}
export function renderChildren(children, values) {
  if (typeof children === "function") {
    return children(getStyleFunc(values));
  }

  return children;
}
export let InteractionState;

(function (InteractionState) {
  InteractionState["None"] = "none";
  InteractionState["Hover"] = "hover";
  InteractionState["Pressed"] = "pressed";
  InteractionState["Focus"] = "focus";
  InteractionState["Disabled"] = "disabled";
})(InteractionState || (InteractionState = {}));

export let VariantPropType;

(function (VariantPropType) {
  VariantPropType["Default"] = "default";
  VariantPropType["Enum"] = "enum";
  VariantPropType["Boolean"] = "boolean";
})(VariantPropType || (VariantPropType = {}));

const propErrorDescriptions = {
  [VariantPropType.Boolean]: booleanPropErrorMessage,
  [VariantPropType.Enum]: enumPropErrorMessage,
};

function booleanPropErrorMessage(prop) {
  return `${prop.propName} is a boolean prop but was passed a non-boolean value.`;
}

function enumPropErrorMessage(prop) {
  return `${
    prop.propName
  } is an enum with supported values: ${prop.propValues.join(" | ")}`;
}

function validateVariantProp(prop, value) {
  if (prop.type === VariantPropType.Boolean) {
    return typeof value === "boolean";
  }

  return typeof value === "string" && prop.propValues.includes(value);
}

export function findSetVariantProps(propTypes, props) {
  if (!exists(props)) {
    return [];
  }

  return propTypes
    .filter((p) => exists(props[p.propName]))
    .reduce((arr, p) => {
      if (!validateVariantProp(p, props[p.propName])) {
        console.warn(
          `Invalid value for prop ${p.propName}. ${propErrorDescriptions[
            p.type
          ](p)}`
        );
        return arr;
      }

      switch (p.type) {
        case VariantPropType.Boolean:
          if (props[p.propName] === true) {
            return [
              ...arr,
              {
                propName: p.propName,
              },
            ];
          } else {
            return arr;
          }

        case VariantPropType.Enum:
          return [
            ...arr,
            {
              propName: p.propName,
              propValue: props[p.propName],
            },
          ];

        default:
          throw Error();
      }
    }, []);
}

function getStyles(styles, propValue) {
  switch (styles.type) {
    case "default":
      return styles.layers;

    case "boolean":
      return styles.layers;

    case "enum": {
      const container = styles.values.find((v) => v.propValue === propValue);

      if (!exists(container)) {
        console.error(
          `No styles found for prop ${styles.propName} with value ${propValue}`
        );
        return {};
      }

      return container.layers;
    }
  }
}

export function useRootProps(props, states, variants = []) {
  const { internal, style, className, innerRef, testId } = props;
  const disabledStyle = states.includes(InteractionState.Disabled)
    ? {
        cursor: "not-allowed",
      }
    : {};
  const activeVariants = [...variants, ...internal.activeVariants];
  const vislyClasses = getRootClasses({
    scope: internal.scope,
    layerId: internal.layerId,
    setVariantProps: activeVariants,
    states,
  });
  const finalStyle = { ...(exists(style) ? style : {}), ...disabledStyle };
  const finalClassName = [className, vislyClasses].filter(exists).join(" ");
  const reactProps = shallowPick(
    {
      tabIndex: props.tabIndex,
      role: props.role,
      onAuxClick: props.onAuxClick,
      onAuxClickCapture: props.onAuxClickCapture,
      onClick: props.onClick,
      onClickCapture: props.onClickCapture,
      onContextMenu: props.onContextMenu,
      onContextMenuCapture: props.onContextMenuCapture,
      onDoubleClick: props.onDoubleClick,
      onDoubleClickCapture: props.onDoubleClickCapture,
      onDrag: props.onDrag,
      onDragCapture: props.onDragCapture,
      onDragEnd: props.onDragEnd,
      onDragEndCapture: props.onDragEndCapture,
      onDragEnter: props.onDragEnter,
      onDragEnterCapture: props.onDragEnterCapture,
      onDragExit: props.onDragExit,
      onDragExitCapture: props.onDragExitCapture,
      onDragLeave: props.onDragLeave,
      onDragLeaveCapture: props.onDragLeaveCapture,
      onDragOver: props.onDragOver,
      onDragOverCapture: props.onDragOverCapture,
      onDragStart: props.onDragStart,
      onDragStartCapture: props.onDragStartCapture,
      onDrop: props.onDrop,
      onDropCapture: props.onDropCapture,
      onMouseDown: props.onMouseDown,
      onMouseDownCapture: props.onMouseDownCapture,
      onMouseEnter: props.onMouseEnter,
      onMouseLeave: props.onMouseLeave,
      onMouseMove: props.onMouseMove,
      onMouseMoveCapture: props.onMouseMoveCapture,
      onMouseOut: props.onMouseOut,
      onMouseOutCapture: props.onMouseOutCapture,
      onMouseOver: props.onMouseOver,
      onMouseOverCapture: props.onMouseOverCapture,
      onMouseUp: props.onMouseUp,
      onMouseUpCapture: props.onMouseUpCapture,
      onTouchCancel: props.onTouchCancel,
      onTouchCancelCapture: props.onTouchCancelCapture,
      onTouchEnd: props.onTouchEnd,
      onTouchEndCapture: props.onTouchEndCapture,
      onTouchMove: props.onTouchMove,
      onTouchMoveCapture: props.onTouchMoveCapture,
      onTouchStart: props.onTouchStart,
      onTouchStartCapture: props.onTouchStartCapture,
      onPointerDown: props.onPointerDown,
      onPointerDownCapture: props.onPointerDownCapture,
      onPointerMove: props.onPointerMove,
      onPointerMoveCapture: props.onPointerMoveCapture,
      onPointerUp: props.onPointerUp,
      onPointerUpCapture: props.onPointerUpCapture,
      onPointerCancel: props.onPointerCancel,
      onPointerCancelCapture: props.onPointerCancelCapture,
      onPointerEnter: props.onPointerEnter,
      onPointerLeave: props.onPointerLeave,
      onPointerOver: props.onPointerOver,
      onPointerOverCapture: props.onPointerOverCapture,
      onPointerOut: props.onPointerOut,
      onPointerOutCapture: props.onPointerOutCapture,
      onGotPointerCapture: props.onGotPointerCapture,
      onGotPointerCaptureCapture: props.onGotPointerCaptureCapture,
      onLostPointerCapture: props.onLostPointerCapture,
      onLostPointerCaptureCapture: props.onLostPointerCaptureCapture,
      onScroll: props.onScroll,
      onScrollCapture: props.onScrollCapture,
      onWheel: props.onWheel,
      onWheelCapture: props.onWheelCapture,
      onKeyDown: props.onKeyDown,
      onKeyDownCapture: props.onKeyDownCapture,
      onKeyPress: props.onKeyPress,
      onKeyPressCapture: props.onKeyPressCapture,
      onKeyUp: props.onKeyUp,
      onKeyUpCapture: props.onKeyUpCapture,
      onFocus: props.onFocus,
      onFocusCapture: props.onFocusCapture,
      onBlur: props.onBlur,
      onBlurCapture: props.onBlurCapture,
    },
    exists
  );
  return useMemo(
    () => ({
      style: finalStyle,
      className: finalClassName,
      variants: activeVariants,
      innerRef,
      testId,
      reactProps,
      values: stylesForState({
        styles: internal.styles,
        states,
        variants: activeVariants,
      }),
    }),
    [
      finalStyle,
      finalClassName,
      activeVariants,
      innerRef,
      testId,
      internal.styles,
      states,
      reactProps,
    ]
  );
}
export function getRootClasses(args) {
  const variantClasses = [
    `__visly_default`,
    ...args.setVariantProps.map((p) =>
      exists(p.propValue)
        ? `__visly_${p.propName}-${p.propValue}`
        : `__visly_${p.propName}`
    ),
  ].join(" ");
  const stateClasses = args.states
    .map((state) => `__visly_state_${state}`)
    .join(" ");
  return `${getLayerClass(
    args.layerId,
    args.scope
  )} ${variantClasses} ${stateClasses}`;
}
export function getLayerClass(layerId, scope) {
  const scopeClass = exists(scope) ? `__visly_scope_${scope}` : "";
  return `__visly_reset ${scopeClass}_${layerId}`;
}
export const entries = Object.entries;

function mapValues(obj, fn) {
  const acc = {};

  for (const [k, v] of entries(obj)) {
    acc[k] = fn(v);
  }

  return acc;
}

export function mergeDeep(...vals) {
  function merge(a, b) {
    if (
      exists(a) &&
      typeof a === "object" &&
      exists(b) &&
      typeof b === "object"
    ) {
      const out = { ...a };

      for (const [k, v] of entries(b)) {
        out[k] = merge(out[k], v);
      }

      return out;
    }

    return exists(b) ? b : a;
  }

  return vals.reduce(merge, {});
}

function shallowPick(obj, predicate) {
  const result = {};

  for (const key of Object.keys(obj)) {
    if (predicate(obj[key])) {
      result[key] = obj[key];
    }
  }

  return result;
}

export const stylesForState = (args) => {
  const { styles, states: _states = [], variants } = args;
  const stylesToMerge = [
    getStyles(styles.find((s) => s.type === "default")) || {},
  ];
  const states = [..._states];

  if (!states.includes(InteractionState.None)) {
    states.unshift(InteractionState.None);
  }

  if (exists(variants)) {
    for (const variant of variants) {
      const variantStyles = styles.find(
        (s) =>
          s.type !== VariantPropType.Default && s.propName === variant.propName
      );

      if (exists(variantStyles)) {
        stylesToMerge.push(getStyles(variantStyles, variant.propValue));
      }
    }
  }

  return mergeDeep(
    ...stylesToMerge.map((variantStyles) =>
      mapValues(variantStyles, (stylesPerState) =>
        mergeDeep({}, ...states.map((state) => stylesPerState[state]))
      )
    )
  );
};

function useInteractionState(props) {
  const {
    disabled = false,
    _hovered = false,
    _focused = false,
    _pressed = false,
  } = props;
  const [hovered, setHovered] = useState(false);
  const [focused, setFocused] = useState(false);
  const [pressed, setPressed] = useState(false);
  const pairs = [
    [InteractionState.None, true],
    [InteractionState.Hover, _hovered || hovered],
    [InteractionState.Focus, _focused || focused],
    [InteractionState.Pressed, _pressed || pressed],
  ];
  const states = disabled
    ? [InteractionState.None, InteractionState.Disabled]
    : pairs.filter((s) => s[1]).map((s) => s[0]);
  return {
    states,
    setFocused,
    setPressed,
    setHovered,
  };
}

function setRef(ref, element) {
  if (exists(ref)) {
    if (typeof ref === "function") {
      ref(element);
    } else {
      ref.current = element;
    }
  }
}

export function combineRef(ref1, ref2) {
  return (el) => {
    setRef(ref1, el);
    setRef(ref2, el);
  };
}
export const useMouseHandler = (args) => {
  const {
    isInside,
    setPressed,
    setHovered,
    onMouseEnter = noop,
    onMouseLeave = noop,
    onMouseMove = noop,
    onMouseDown = noop,
    onMouseUp = noop,
  } = args;
  const [isHandlingEvents, setIsHandlingEvents] = useState(false);
  const handler = useMemo(() => {
    const mouseHandler = createMouseHandler({
      isInside,
      setHovered,
      setIsHandlingEvents,
      setPressed,
    });
    return {
      onMouseEnter: (event) => {
        onMouseEnter(event);
        mouseHandler.onMouseEnter(event);
      },
      onMouseLeave: (event) => {
        onMouseLeave(event);
        mouseHandler.onMouseLeave(event);
      },
      onMouseMove: (event) => {
        onMouseMove(event);
        mouseHandler.onMouseMove(event);
      },
      onMouseDown: (event) => {
        onMouseDown(event);
        mouseHandler.onMouseDown(event);
      },
      onMouseUp: (event) => {
        onMouseUp(event);
        mouseHandler.onMouseUp(event);
      },
    };
  }, [
    isInside,
    onMouseDown,
    onMouseEnter,
    onMouseLeave,
    onMouseMove,
    onMouseUp,
    setHovered,
    setPressed,
  ]);
  const onDocumentMouseUp = useCallback(() => {
    if (isHandlingEvents) {
      setIsHandlingEvents(false);
      setPressed(false);
    }
  }, [isHandlingEvents, setPressed]);
  useEffect(() => {
    document.addEventListener("mouseup", onDocumentMouseUp);
    return () => {
      document.removeEventListener("mouseup", onDocumentMouseUp);
    };
  }, [onDocumentMouseUp]);
  return handler;
};
export const createMouseHandler = ({
  isInside,
  setIsHandlingEvents,
  setPressed,
  setHovered,
}) => {
  return {
    onMouseEnter: (event) => setHovered(isInside(event.target)),
    onMouseLeave: (_) => setHovered(false),
    onMouseMove: (event) => setHovered(isInside(event.target)),
    onMouseDown: (event) => {
      setIsHandlingEvents(isInside(event.target));
      setPressed(isInside(event.target));
      event.stopPropagation();
    },
    onMouseUp: (_) => setPressed(false),
  };
};
export function useIsInside(rootRef) {
  return useCallback(
    (target) => {
      if (!exists(rootRef)) return false;
      let parent = target;

      while (exists(parent)) {
        if (parent === rootRef.current) {
          return true;
        }

        parent = parent.parentNode;
      }

      return false;
    },
    [rootRef]
  );
}
export function useEventHandlers(props) {
  const {
    ref,
    onClick,
    onKeyDown,
    onKeyUp,
    onFocus,
    onBlur,
    onMouseDown,
    onMouseEnter,
    onMouseLeave,
    onMouseMove,
    onMouseUp,
  } = props;
  const { states, setFocused, setHovered, setPressed } = useInteractionState(
    props
  );
  const isInside = useIsInside(ref);
  const mouseHandler = useMouseHandler({
    isInside,
    setHovered,
    setPressed,
    onMouseDown,
    onMouseEnter,
    onMouseLeave,
    onMouseMove,
    onMouseUp,
  });
  const handlers = useMemo(
    () => ({
      onKeyDown: onKeyDown,
      onKeyUp: onKeyUp,
      onFocus: (e) => {
        setFocused(true);

        if (onFocus) {
          onFocus(e);
        }

        e.stopPropagation();
      },
      onBlur: (e) => {
        setFocused(false);

        if (onBlur) {
          onBlur(e);
        }
      },
      onClick: (e) => {
        if (exists(onClick)) {
          onClick(e);
          e.stopPropagation();
        }
      },
      ...mouseHandler,
    }),
    [setFocused, onKeyDown, onKeyUp, onFocus, onBlur, onClick, mouseHandler]
  );

  if (states.includes(InteractionState.Disabled)) {
    return {
      states,
      handlers: {},
      setFocused: () => {},
    };
  }

  return {
    states,
    setFocused,
    handlers,
  };
}
export const props = [
  "bottom",
  "left",
  "right",
  "top",
  "width",
  "height",
  "x",
  "y",
];

function rectChanged(a, b) {
  return props.some((prop) => a[prop] !== b[prop]);
}

export const useRect = ({ ref: refOpt, observe = false }) => {
  const [rect, setRect] = useState({
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    height: 0,
    x: -10000,
    y: -10000,
  });
  const [node, setNode] = useState(null);
  const ref = useCallback((node) => {
    setNode(node);
  }, []);
  const currentRefOpt = exists(refOpt) ? refOpt.current : null;
  useEffect(() => {
    if (exists(currentRefOpt)) {
      setNode(currentRefOpt);
    }
  }, [currentRefOpt]);
  useLayoutEffect(() => {
    if (exists(node)) {
      const updateRect = () => {
        const newRect = node.getBoundingClientRect();

        if (rectChanged(newRect, rect)) {
          setRect(newRect);
        }
      };

      updateRect();

      if (observe) {
        window.addEventListener("resize", updateRect);
        return () => {
          window.removeEventListener("resize", updateRect);
        };
      }
    }
  });
  return [rect, ref];
};
export function useTimeout(callback, delay, deps) {
  return useEffect(() => {
    const handle = setTimeout(callback, delay);
    return () => clearTimeout(handle);
  }, [callback, delay]);
}
export const noop = () => {};
export const NoSelectStyles = {
  userSelect: "none",
  WebkitUserSelect: "none",
  KhtmlUserSelect: "none",
  MozUserSelect: "none",
};
export function makeCompositeDefaultProps(defaultPropValues, activeVariants) {
  const values = stylesForState({
    styles: defaultPropValues,
    states: [InteractionState.None],
    variants: activeVariants,
  });
  return (layerId) => {
    return exists(values[layerId]) ? values[layerId] : {};
  };
}
