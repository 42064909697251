// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "./reset.css";
import "./Input.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "./_internal_utils";
import { SpacerPrimitive, IconPrimitive } from "./_internal_primitives";
import { Root, InputPrimitive } from "./_internal_input";

const styles = [
  {
    type: "default",
    layers: {
      input: {
        none: {
          placeholderText: "Placeholder",
        },
      },
      "2x9zSCdnq6": {
        none: {
          role: "img",
          src: require("../assets/57b8c7c8-913a-452e-bd4d-e7702b618b0b@1x.png"),
          useMask: true,
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const InputContext = createContext(null);

function Input(_props) {
  const defaults = useContext(InputContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <Root
      {...props}
      key="root"
      addSpacing={false}
      internal={{
        styles: styles,
        layerId: "root",
        scope: "AzHv2NByua",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <IconPrimitive
            className={"__visly_reset __visly_scope_AzHv2NByua_2x9zSCdnq6"}
            key={"2x9zSCdnq6"}
            useMask={getStyle("2x9zSCdnq6", "useMask")}
            src={getStyle("2x9zSCdnq6", "src")}
          />
          <SpacerPrimitive
            className={"__visly_reset __visly_scope_AzHv2NByua_G1kYNxteiQ"}
            key={"G1kYNxteiQ"}
          />
          <InputPrimitive
            className={"__visly_reset __visly_scope_AzHv2NByua_input"}
            key={"input"}
            placeholder={
              exists(props.placeholder)
                ? props.placeholder
                : getStyle("input", "placeholderText")
            }
          />
        </>
      )}
    </Root>
  );
}

Input.__variants = [];

export default Input;
