// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "./reset.css";
import "./StreamlineLinkText.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "./_internal_utils";
import { RootPrimitive, TextPrimitive } from "./_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      NdFZGzZUia: {
        none: {
          text: "Streamline Icons",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const StreamlineLinkTextContext = createContext(null);

function StreamlineLinkText(_props) {
  const defaults = useContext(StreamlineLinkTextContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="Vw1J9VNuYS"
      addSpacing={false}
      internal={{
        styles: styles,
        layerId: "Vw1J9VNuYS",
        scope: "6eGpMYo7hC",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <TextPrimitive
          className={"__visly_reset __visly_scope_6eGpMYo7hC_NdFZGzZUia"}
          key={"NdFZGzZUia"}
          text={getStyle("NdFZGzZUia", "text")}
        />
      )}
    </RootPrimitive>
  );
}

StreamlineLinkText.__variants = [];

export default StreamlineLinkText;
