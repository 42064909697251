// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import React, { createContext, useContext, useRef } from "react";
import {
  exists,
  useRootProps,
  useEventHandlers,
  InteractionState,
  combineRef,
  renderChildren,
  noop,
} from "./_internal_utils";
const Context = createContext(null);
export function Root(props) {
  const ref = useRef();
  const { states, handlers } = useEventHandlers({
    ref,
    ...props,
  });

  const onChange = (e) => {
    if (exists(props.onChange)) {
      props.onChange(e.currentTarget.value);
    }
  };

  const {
    style,
    className,
    testId,
    innerRef,
    values,
    reactProps,
  } = useRootProps(props, states);
  return (
    <div
      ref={combineRef(props.measureRef, combineRef(innerRef, ref))}
      data-testid={testId}
      {...reactProps}
      {...handlers}
      onFocus={noop}
      onBlur={noop}
      className={className}
      style={style}
    >
      <Context.Provider
        value={{
          inputRef: props.inputRef,
          onFocus: handlers.onFocus,
          onBlur: handlers.onBlur,
          onChange,
          value: props.value,
          disabled: states.includes(InteractionState.Disabled),
        }}
      >
        {renderChildren(props.children, values)}
      </Context.Provider>
    </div>
  );
}
export function InputPrimitive(props) {
  const { onFocus, onBlur, onChange, inputRef, value, disabled } = useContext(
    Context
  );
  const placeholder = props.placeholder;
  return (
    <input
      type="text"
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      ref={combineRef(props.measureRef, inputRef)}
      placeholder={placeholder}
      disabled={disabled}
      className={props.className}
      style={{
        borderImage: "none",
        outline: "none",
        background: "none",
        ...(disabled
          ? {
              cursor: "not-allowed",
            }
          : {}),
      }}
    />
  );
}
