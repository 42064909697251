// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
require("./fonts.css");

export const accent = {
  fontFamily: "Visly Roboto",
  fontSize: "14px",
  fontWeight: 500,
  fontStyle: "normal",
  lineHeight: "20px",
};
export const body = {
  fontFamily: "Visly Roboto",
  fontSize: "16px",
  fontWeight: 400,
  fontStyle: "normal",
  lineHeight: "24px",
};
export const bodyLarge = {
  fontFamily: "Visly Roboto",
  fontSize: "18px",
  fontWeight: 400,
  fontStyle: "normal",
  lineHeight: "26px",
};
export const heading = {
  fontFamily: "Visly Roboto",
  fontSize: "18px",
  fontWeight: 500,
  fontStyle: "normal",
  lineHeight: "26px",
};
