// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "./reset.css";
import "./ValidationResultIcon.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "./_internal_utils";
import { RootPrimitive, TextPrimitive } from "./_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      XNuAjLVdZU: {
        none: {
          text: "?",
        },
      },
    },
  },
  {
    type: "enum",
    propName: "status",
    values: [
      {
        propValue: "valid",
        layers: {
          XNuAjLVdZU: {
            none: {
              text: "✓",
            },
          },
        },
      },
      {
        propValue: "invalid",
        layers: {
          XNuAjLVdZU: {
            none: {
              text: "✗",
            },
          },
        },
      },
      {
        propValue: "unknown",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "enum",
    propName: "status",
    values: [
      {
        propValue: "valid",
        layers: {},
      },
      {
        propValue: "invalid",
        layers: {},
      },
      {
        propValue: "unknown",
        layers: {},
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "enum",
    propName: "status",
    propValues: ["valid", "invalid", "unknown"],
  },
];

export const ValidationResultIconContext = createContext(null);

function ValidationResultIcon(_props) {
  const defaults = useContext(ValidationResultIconContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="E5PLsccuzL"
      addSpacing={false}
      internal={{
        styles: styles,
        layerId: "E5PLsccuzL",
        scope: "KtRZVu1zch",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <TextPrimitive
          className={"__visly_reset __visly_scope_KtRZVu1zch_XNuAjLVdZU"}
          key={"XNuAjLVdZU"}
          text={getStyle("XNuAjLVdZU", "text")}
        />
      )}
    </RootPrimitive>
  );
}

ValidationResultIcon.__variants = [
  {
    name: "status",
    type: "group",
    variants: ["valid", "invalid", "unknown"],
  },
];

export default ValidationResultIcon;
