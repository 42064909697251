// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
export const color = {
  black700: "65f847a3-aa95-4f42-9cdf-cd8dbe73d8c2",
  black800: "1ecb2015-0125-4ee5-9d8c-feb10369dac8",
  black900: "0b266fa6-2ad0-4f8a-90ff-56ca470c31c7",
  gray200: "544af3b8-8dfd-4496-ae15-220dc87c055f",
  gray300: "b6b28ca8-f2fd-4289-b56e-d98eefcbfcf9",
  gray400: "3aef1344-5ee8-443f-9160-554f2bba1159",
  gray500: "c4166644-3768-4c22-a10b-e1c9b6d61b96",
  indigo900: "f5b9611c-598b-48bf-85f3-e0b4c4467cf0",
  mint400: "2e800351-2272-4773-a849-66d9cc1dbba7",
  mint500: "32f153bc-91ca-4170-9823-f1d2cd61ce38",
  mint600: "3d698970-2b19-4497-bc20-da4de161a26f",
  orange500: "41e4c4a6-9039-4bd3-8b50-13606047fef5",
  purple200: "c4cc314d-1c27-4d94-a2e3-694f87f9940e",
  purple400: "efb8d429-1324-49bd-aade-fed9012c47bc",
  purple500: "7ce0b38c-2947-49fa-a348-06bd21664357",
  purple600: "e8face8d-a42e-40ac-83e2-9bb4a97ecb4d",
  red400: "d1b0cc7c-00fc-4113-a9db-3d90f2dc54bb",
  white: "1a46fecf-73b1-44d3-88f8-69ef863d110a",
};
export const icon = {
  flourishRight: "432f9af9-794c-4188-8117-7d3751502be6",
  flourishTopLeft: "cd476409-efa8-4810-b7c8-0068494e85d2",
  heart: "873969b2-f5fd-49f3-96aa-45f9515bff85",
  heartFill: "72d60e6c-6c0f-47f3-ba45-a6a42cf6250e",
  message: "c960c1cc-f05b-4842-972b-c497154e2e5b",
  repeat: "7900b30b-3148-4379-bb23-489f4b637df5",
  share: "c0d36d31-1367-4c7a-91be-c1ac14e5c3c5",
  streamlineIconServer1140X140: "57b8c7c8-913a-452e-bd4d-e7702b618b0b",
  vislyCheck: "567b267e-ef5e-4078-b042-d5c96e513048",
  vislyRadioBorder: "d0171f72-9dbe-4e54-b469-a4432d2beca6",
  vislyRadioCenter: "22ef53ca-3265-49f9-85f5-c00ff038c977",
  vislySelect: "562e1b22-bb85-483b-b4a0-52c23363fef5",
};
export const font = {
  "Roboto-Bold": "d7d2c5b4-4c7b-4efb-9c21-0ef70f800be8",
  "Roboto-Medium": "925b1f14-e32f-4af1-b2ee-80132fd73b20",
  "Roboto-Regular": "c775fc02-e85e-4e5f-b67a-b62e4ed19fb9",
};
export const textstyle = {
  accent: "5a5b282c-fbe5-4efb-8163-9d8e2399991c",
  body: "199b4f45-79d7-45f8-af30-5d9a0d539bcc",
  bodyLarge: "f44a4b6d-a6ec-4bbd-a6b5-46a55cba7085",
  heading: "356b1e56-5565-4a97-bb9f-d812d1c7c56e",
};
export const component = {
  Input: "50e2110b-155c-4778-a63c-44c9c1c8b4f1",
  MainPage: "4809cadd-f312-4121-aa60-7f9dbc8e5b31",
  StreamlineLinkText: "2db1f5c4-3adc-4137-b620-de6a47537b58",
  ValidationResultIcon: "98f29061-122d-4b54-8744-94294e0c0d56",
};
